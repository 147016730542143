.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.reward-modal-bg {
  background-color: #6531D9;
  color: white;
}

.bg-light-gray {
  background-color: #F5F5F5;
  border-radius: 8px;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.register-workshop-modal-mobile-footer {
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }
}

.video-placeholder-image {
  max-width: 95%;
}

.video-placeholder-image:hover {
  cursor: pointer;
}

.register-form-check>*>.form-check-input {
  float: none !important;
  margin-right: 8px;
}

.workshop-header-img {
  z-index: -1;

  height: auto !important;

  @media only screen and (max-width: 992px) {}
}

.workshop-header-video {
  text-align: center;

  @media (max-width: 992px) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    text-align: left;
    margin-left: 100px;
    margin-top: 0;
    margin-left: 20px;
  }
}

.workshop-header-text {
  margin-left: 60px;
  margin-top: 16px;
  line-height: 1;

  &>p {
    margin-bottom: 0;
  }

  @media (max-width: 1279px) {
    text-align: center;
    margin: 16px;
  }
}

.workshop-footer-img {
  position: relative !important;
}

.workshop-footer {
  margin-top: -14em !important;

  @media (max-width: 992px) {
    margin-top: -5em !important;
  }
}

.image-container {
  display: block;

  .image {
    width: 100%;
    height: 100%;
    position: relative !important;
    object-fit: cover; // Optional
  }
}

.question-box {
  background: #EAEDEC;
  border-radius: 16px;
}

.swiper-slide {
  overflow: auto;
}

.btn-close:hover {
  cursor: pointer;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.quiz-row {
  & *>p {
    font-size: 1.1rem;
  }
}

.quiz-correct {
  text-align: center;
  margin-top: 150px;

  @media (max-width: 576px) {
    margin-top: 0;
  }
}

.quiz-incorrect {
  text-align: center;
  margin-top: 150px;

  @media (max-width: 576px) {
    margin-top: 0;
  }
}

.product-name {
  font-size: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.activity-swiper {
  padding-bottom: 20px !important;

  &>*>.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 4px 8px !important;
    cursor: pointer;
  }
}

span.swiper-pagination-bullet {
  background-color: black;
}

.google-login {
  background: url(/assets/img/btn_google_signin_light_normal_web.png) no-repeat center/100%;
  border: none;
  width: 200px;
  height: 60px;
}

.google-login:focus {
  background-image: url(/assets/img/btn_google_signin_light_focus_web.png);
}

.google-login:hover {
  background-image: url(/assets/img/btn_google_signin_light_pressed_web.png);
}

.phone-input {
  height: 45px !important;
  width: 100% !important;
}

.welcome-list-group .list-group-item.active {
  color: black;
  background-color: #EEF5FF;
  border-color: #6029D9;
}

.welcome-image-container {
  height: 50px;
  width: 50px;
  margin-right: 12px;
  position: relative;

  @media (min-width: 576px) {
    height: 100px;
    width: 100%;
    margin-bottom: 16px;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: gray;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.otp {
  letter-spacing: .25em;
}

.login-bg {
  background-image: url(/assets/img/login-bg.webp);

  @media (min-width: 1078px) {
    background-size: cover;
  }
}

.read-more {
  input[type="checkbox"] {
    display: none;
  }

  label {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }

  label:after {
    content: "more";
  }

  input:checked~label:after {
    content: "less";
  }

  .inner {
    max-height: 84px;
    overflow: hidden;
    transition: all .3s ease;
  }

  input:checked+.inner {
    max-height: fit-content;
  }
}

.breadcrumb {
  font-size: small !important;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 4px !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  padding-right: 4px !important;
}
